<template>
  <v-container class="px-5 px-md-16">
      <v-btn dark class="white elevation-2 mb-10" fab :to="{ name : 'SelectedBusiness', params: { id : selectedBusinessId }}"> <v-icon color="primary">mdi-arrow-left</v-icon></v-btn>
      <div class="text-h5 font-weight-medium mb-10 primary--text">My Business Card</div>
      <v-row class="mt-10">
        <v-col cols="12" md="4">
          <add-card />
        </v-col>
      </v-row>
      <v-card elevation="0" class="grey lighten-5 rounded-lg pa-5 mt-15">
         <v-toolbar flat class="grey lighten-5">
         <v-toolbar-title class="text-h6 font-weight-medium primary--text mb-4">Transaction History</v-toolbar-title>
         <v-spacer></v-spacer>
         <v-select 
            v-model="transaction"
            :items="['Select Card Type']"
            solo dense outlined
            @input="getTransactions()"
         >
         </v-select>
         </v-toolbar>
         <!--v-text-field v-model="search" append-icon="mdi-magnify" label="Search transaction" single-line dense outlined hide-details></v-text-field-->
         <v-data-table
         :headers="headers"
         :items="history"
         hide-default-footer
         :loading="loading"
         class="myTable rounded-lg"
         loading-text="Loading data... Please wait"
         >
         </v-data-table>
      </v-card>
    
      <v-dialog v-model="transferbusiness" width="500">
        <v-card class="pa-6 px-12 form-colored-bg">
          <div class="">
            <div class="mb-2 primary--text text-h5 font-weight-medium">Transfer</div>
            <div class="text-body-2 text--secondary">Withdraw your savings by transfering to your bank account or wallet</div>
          </div>
          <v-form class="mt-6" lazy-validation v-model="valid" ref="transfer">

            <div class="mb-1 primary--text font-weight-medium">Account Number</div>
            <v-text-field v-model="form.account_number" outlined required :rules="[rules.required]"></v-text-field>

            <div class="mb-1 primary--text font-weight-medium">Enter amount</div>
            <v-text-field prefix="₦" v-model="form.amount" outlined required :rules="[rules.required, rules.min]"></v-text-field>

            <div class="mb-1 primary--text font-weight-medium">Description</div>
            <v-text-field v-model="form.description" outlined required :rules="[rules.required]"></v-text-field>

            <div class="mb-1 primary--text font-weight-medium">Enter Transaction PIN</div>
            <v-text-field v-model="form.transaction_pin" outlined required :rules="[rules.required]"></v-text-field>

            <v-checkbox 
              @click="savebeneficiary('wallet')"
              v-model="addbeneficiary"
              :disabled="!form.account_number" 
              label="Add as beneficiary (Staff)" 
              outlined
              class="my-0 ben"
            ></v-checkbox>
            <v-btn class="primary" :loading="btnloading" @click="$refs.transfer.validate() ? transfer() : null" block x-large>Transfer</v-btn>
            <v-btn class="mt-3" depressed large @click="transferbusiness = !transferbusiness" block>Cancel</v-btn>
          </v-form>
        </v-card>
      </v-dialog>
      
      <v-dialog v-model="bizinfo" width="80%">
        <v-card class="pa-6 px-10">
          <v-toolbar flat>
            <v-toolbar-title class="secondary--text text-h6 font-weight-medium">Business Information</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="mx-2" fab small color="gray lighten-5" depressed @click="bizinfo = !bizinfo"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-row class="mt-5">
            <v-col cols="4">
              <div class="text-body-2 primary--text">Business Name</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.name | capitalize }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">Business Category</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.category | capitalize }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">Business Location</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.location | capitalize }}</div>
            </v-col>
          </v-row>
          <v-row class="mt-5">
            <v-col cols="4">
              <div class="text-body-2 primary--text">State</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.state | capitalize }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">City</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.city | capitalize }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">Country</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.country | capitalize }}</div>
            </v-col>
          </v-row>
          <v-row class="mt-5">
            <v-col cols="4">
              <div class="text-body-2 primary--text">Date Created</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.created_at | formatDate }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">Account Number</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.category | capitalize }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">Account Balance</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.balance || '0' | formatPrice }}</div>
            </v-col>
          </v-row>

          <v-divider class="mt-10 mb-5"></v-divider>
           <v-toolbar flat>
            <v-toolbar-title class="secondary--text text-h6 font-weight-medium">Business Documents</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-container class="text-center">Kyc documents not found</v-container>
          <!--v-row class="mt-5">
            <v-col cols="4">
              <div class="text-body-2 primary--text">CAC Certificates</div>
              <v-chip color="blue lighten-4" class="text-body-1 mt-1 font-weight-medium" label text-color="primary">New Tweets <v-icon class="ml-3" color="secondary">mdi-eye</v-icon></v-chip>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">CAC Application Doc.</div>
              <v-chip color="blue lighten-4" class="text-body-1 mt-1 font-weight-medium" label text-color="primary">New Tweets <v-icon class="ml-3" color="secondary">mdi-eye</v-icon></v-chip>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">RC Number</div>
              <v-chip color="blue lighten-4" class="text-body-1 mt-1 font-weight-medium" label text-color="primary">New Tweets <v-icon class="ml-3" color="secondary">mdi-eye</v-icon></v-chip>
            </v-col>
          </v-row>
          <v-row class="mt-5">
            <v-col cols="4">
              <div class="text-body-2 primary--text">MEMART/Ariticle of Ass.</div>
              <v-chip color="blue lighten-4" class="text-body-1 mt-1 font-weight-medium" label text-color="primary">New Tweets <v-icon class="ml-3" color="secondary">mdi-eye</v-icon></v-chip>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">TIN (Tax Identification Number)</div>
              <v-chip color="blue lighten-4" class="text-body-1 mt-1 font-weight-medium" label text-color="primary">New Tweets <v-icon class="ml-3" color="secondary">mdi-eye</v-icon></v-chip>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">Proof of Address</div>
              <v-chip color="blue lighten-4" class="text-body-1 mt-1 font-weight-medium" label text-color="primary">New Tweets <v-icon class="ml-3" color="secondary">mdi-eye</v-icon></v-chip>
            </v-col>
          </v-row-->
          
          <v-divider class="mt-10 mb-5"></v-divider>
           <v-toolbar flat>
            <v-toolbar-title class="secondary--text text-h6 font-weight-medium">Business Owners</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-container class="text-center">Owner details not found</v-container>
          <!--v-row class="mt-5">
            <v-col cols="4">
              <div class="text-body-2 primary--text">Business Name</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.name | capitalize }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">Business Category</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.category | capitalize }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-2 primary--text">Business Location</div>
              <div class="text-body-1 primary--text font-weight-medium">{{ selectedInfo.location | capitalize }}</div>
            </v-col>
          </v-row-->
          <v-container class="text-right mt-10">
            <v-btn class="primary" @click="bizinfo = !bizinfo; showkyc = true">Upload Business KYC</v-btn>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showkyc" width="800">
        <v-card class="pa-6 px-10">
          <v-stepper v-model="el" class="elevation-0">
            <v-stepper-content step="1">
              <div class="mb-2 primary--text text-h5 font-weight-medium">Business KYC</div>
              <div class="text-body-2 text--secondary">Submit your business documents.</div>
              <v-form class="mt-6" lazy-validation v-model="valid" ref="kyc1">
                <v-row>
                  <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">CAC Certificate</div>
                    <v-text-field
                      v-model="form.cac_cert"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">RC Number</div>
                    <v-text-field
                      v-model="form.rc_number"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">CAC Application Document</div>
                    <v-text-field
                      v-model="form.cac_doc"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">MEMART/Ariticle of Association/MOU</div>
                    <v-text-field
                      v-model="form.memart"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">TIN (Tax Identification number)</div>
                    <v-text-field
                      v-model="form.tin"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">Proof of Address</div>
                    <v-text-field
                      v-model="form.poadr"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>     
                <v-divider class="mt-5 mb-10"></v-divider>           
                <v-btn class="primary" @click="$refs.kyc1.validate() ? (el = 2) : null" large>Next</v-btn>
                <v-btn @click="showkyc = !showkyc" large light class="ml-3" depressed>Cancel</v-btn>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2">
              <div class="mb-2 primary--text text-h5 font-weight-medium">Additional Business Information</div>
              <div class="text-body-2 text--secondary">Add list of business owners</div>
              <v-form class="mt-6" lazy-validation v-model="valid" ref="kyc2">
                <v-row>
                  <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">Full name</div>
                    <v-text-field
                      v-model="form.fullname"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">Email</div>
                    <v-text-field
                      v-model="form.email"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">Phone Number</div>
                    <v-text-field
                      v-model="form.phone"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">National ID Number</div>
                    <v-text-field
                      v-model="form.nin"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">BVN</div>
                    <v-text-field
                      v-model="form.bvn"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">Address</div>
                    <v-text-field
                      v-model="form.address"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">State</div>
                    <v-text-field
                      v-model="form.state"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">LGA</div>
                    <v-text-field
                      v-model="form.lga"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <div class="mb-1 primary--text font-weight-medium">City</div>
                    <v-text-field
                      v-model="form.city"
                      outlined
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0 pt-12">
                    <v-btn light depressed large>Save</v-btn>
                    <v-btn class="ml-3 secondary" depressed large>Add More Owners</v-btn>
                  </v-col>
                </v-row>             
                <v-divider class="mt-5 mb-10"></v-divider>   
                <v-btn class="primary" @click="$refs.kyc1.validate() ? uploadkyc() : null" large>Save</v-btn>
                <v-btn @click="showkyc = !showkyc" large light class="ml-3" depressed>Cancel</v-btn>
              </v-form>
            </v-stepper-content>
          </v-stepper>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addbene" width="500">
        <v-card class="pa-6 px-12 form-colored-bg">
          <div class="mb-2 primary--text text-h5 font-weight-medium">Add Beneficiary</div>
          <v-divider></v-divider>
          <v-form class="mt-6" lazy-validation v-model="valid" ref="bene">
            <div class="mb-1 primary--text font-weight-medium">Wallet ID</div>
            <v-text-field
              v-model="bene.account_number"
              outlined
              required
              :rules="[rules.required]"
            ></v-text-field>
            <!--div class="mb-1 primary--text font-weight-medium">Beneficiary's Name</div>
            <v-text-field
              prefix="₦"
              v-model="form.amount"
              outlined
              required
              :rules="[rules.required]"
            ></v-text-field-->
            <div class="mb-1 primary--text font-weight-medium">Amount (Salary)</div>
            <v-text-field
              prefix="₦"
              v-model="bene.salary"
              outlined
              required
              :rules="[rules.required, rules.min]"
            ></v-text-field>
            <v-btn
              class="primary" :loading="loadbene" @click="$refs.bene.validate() ? savebeneficiary() : null" block x-large>Save Beneficiary</v-btn>
            <v-btn @click="addbene = !addbene; $refs.bene.reset(); loadbene = false" block large light class="mt-3" depressed>Cancel</v-btn
            >
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="topUpDialog" width="500">
        <v-card class="pa-6 px-12 form-colored-bg">
          <div class="mb-2 primary--text text-h5 font-weight-medium">Fund Business Wallet</div>
          <v-divider></v-divider>
          <v-form class="mt-6" lazy-validation v-model="valid" ref="topup">
            <div class="mb-1 primary--text font-weight-medium">Enter Amount</div>
            <v-text-field
              prefix="₦"
              v-model="form.amount"
              outlined
              required
              :rules="[rules.required]"
            ></v-text-field>
            <div class="mb-1 primary--text font-weight-medium">Source of Funding</div>
            <v-select
              outlined
              v-model="form.source"
              required
              :rules="[(v) => !!v || 'Please select source of funding']"
              :items="['Card', 'Wallet']"
            ></v-select>
            <v-btn
              class="primary"
              :loading="topuploading"
              @click="$refs.topup.validate() && $refs.topup.validate() && form.source == 'Wallet' ? topUpBusinessWallet()
                : null
              "
              block
              x-large
              >Submit</v-btn
            >
            <v-btn @click="topUpDialog = !topUpDialog" block large light class="mt-3" depressed>Cancel</v-btn
            >
          </v-form>
        </v-card>
      </v-dialog>
  </v-container>
</template>
<script>
import MiniLoader from '@/components/MiniLoader.vue'
import AddCard from '@/components/business/AddCard.vue'

import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    MiniLoader,
    AddCard
  },
  layout: 'dashboard',
  transition: 'default',
  data() {
    return {
      createNew: '',
      topUpDialog: false,
      inviteDialog: false,
      inviteDialogAgent: false,
      loading: true,
      loaded: false,
      valid: true,
      rules: {
        required: value => !!value || 'This field is required',
        min: value => value >= 10 || "Transfer amount can't go below ₦10",
      },
      showkyc: false,
      menu: false,
      menu1: false,
      activeAccount: '',
      reference: '',
      wallet: '',
      wallet_id: '',
      form: {},
      bizinfo: false,
      transferbusiness: false,
      settings: false,
      card: false,
      selectUser: false,
      selectedUser: [],
      p: [],
      g: [],
      a: [],
      searchedMember: false,
      s: [],
      selectedBusinessId: this.$route.params.id,
      selectedInfo: [],
      today: new Date().toISOString().slice(0,10),
      headers: [
        { text: 'S/N', align: 'start', value: 'type' },
        { text: 'Transaction', value: 'date_deposited' },
        { text: 'Description', value:  'amount' },
        { text: 'Type', value:  'amount' },
        { text: 'Amount', value:  'amount' },
        { text: 'Time', value:  'amount' }
      ],
      beneficiaries: [],
      history: [],
      transaction: 'Select Card Type',
      addbeneficiary: false,
      topuploading: false,
      btnloading: false,
      kycloading: false,
      el: 1,
      addbene: false,
      loadbene: false,
      bene: {}
    }
  },

  computed: {
    ...mapGetters('auth', ['user'])
  },

  filters: {
    capitalize: function (value) {
        if (!value) return ''
            value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },

  methods: {
    ...mapActions('business', ['bizbeneficiaries', 'singlebusiness', 'businesstransfertobank', 'transactionhistory', 'savebenefit']),

    async transfer() {
      this.form.business_id = this.selectedBusinessId
      try {
        this.btnloading = true
        let response = await this.businesstransfertobank(this.form)
        this.$toast.success(response.data.message)
        this.btnloading = false
        } catch(error) {
          this.$toast.error(error.response.data.message)
          this.btnloading = false  
        } finally {
          this.btnloading = false
        }
    },
     
    async uploadkyc() {

    },
    
    getDateDiff() {
      var date1 = new Date()
      var date2 = new Date(this.selectedInfo.end_date)
      var difference_in_time  = date2.getTime() - date1.getTime()
      var difference_in_days = difference_in_time / (1000 * 3600 * 24)
      if(Math.round(difference_in_days) < 0) {
        this.days_left = 0
      } else {
        this.days_left = Math.round(difference_in_days)
      }
    },

    async getBusinessBeneficiaries() {
      const data = {
        business_id  : this.selectedBusinessId
      }
      try {
        let res = await this.bizbeneficiaries(data)
        this.beneficiaries = res.data
      } catch(err) {

      }
    },

    async getSelectedBusiness() {
      const data = {
        id: this.selectedBusinessId
      }
      try {
        let res = await this.singlebusiness(data)
        this.selectedInfo = res.data
        if(this.selectedInfo != '') {
          this.loading = false
          this.loaded = true
        }
      } catch(error) {
        this.loading = false
        this.loaded = false
      }
    },

    async getTransactions() {
      const data = {
        business_id: this.selectedBusinessId
      }
      let res
      switch (this.transaction) {       
        case 'Transfer':
          res = await this.transactionhistory(data)
          this.history = res.data
        break;
        case 'TV Transaction':
          res = await this.transactionhistory(data)
        break;
        case 'Airtime':
          res = await this.transactionhistory(data)
        break;
        case 'Data':
          res = await this.transactionhistory(data)
        break;
        case 'Power':
          res = await this.transactionhistory(data)
        break;
        case 'Sent Transfer':
          res = await this.transactionhistory(data)
          this.history = res.history
        break;
        case 'Recieved Transfer':
          res = await this.transactionhistory(data)
          this.history = res.history
        break;
        default:
          res = await this.transactionhistory(data)
          this.history = res.data
        break;
      }
      
    },

    async getWalletBalance() {
      const data = {
        user_id: this.user.id,
      };
      await this.$store
        .dispatch("auth/wallet", data, { root: true })
        .then((response) => {
          if (response == 0) {
            this.wallet = "0";
          } else {
            this.wallet = response;
          }
        })
    },
    async getLoanBalance() {
      const data = {
        user_id: this.user.id,
      };
      await this.$store
        .dispatch("auth/loanbalance", data, { root: true })
        .then((response) => {
          this.loan_balance = response;
        });
    },
    
    async savebeneficiary() {
      this.bene.business_id = this.selectedBusinessId
      this.loadbene = true

      try{
        let response = await this.savebenefit(this.bene)
        this.$toast.success(response.data.message)
        this.loadbene = false
      } catch(error) {
          if(error.response && error.response.status === 422) {
            this.$toast.error(error.response.data.data.account_number ? error.response.data.data.account_number[0] : '') 
          } else {
            this.$toast.error(error.response.data.message)
          }
        
        this.loadbene = false
      }
    },

  async topUpBusinessWallet() {
      this.topuploading = true
        const data = {
          business_id: this.selectedInfo.id,
          amount: this.selectedInfo.projected_saving
        }
        var res_p = this.$store.dispatch('savings/fundbusinesswallet', data)
      try {
        let res = await res_p
        this.$toast.success(res.message)
          this.loading = this.topUpDialog = false
          this.getSelectedSavings()
          this.$refs.topup.reset()
      } catch(error) {
        this.$toast.error(error.response.data.message)
        this.loading = false
      }
    }
  },

  mounted() {
    this.getTransactions()
    this.getWalletBalance()
    this.getSelectedBusiness()
    this.getBusinessBeneficiaries()
    this.form.user_id = this.user.id
    this.form.source = "Wallet"
  },
}
</script>
<style lang="scss" scoped>
.next-card {
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
  }
}

.ben .form-colored-bg .v-input__slot {
  background-color: white !important;
}

.single-savings-card {
  border: 0.5px solid #d9dbfa;
  box-shadow: 0px 2px 2px rgba(171, 153, 153, 0.25) !important;
  box-sizing: border-box;
  border-radius: 5px;
  .description {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 132.5%;
    color: #c1a13d;
  }
  &:hover {
    background: #ffffff;
    border: 0.5px solid #484dc7;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px 9px rgba(219, 200, 200, 0.25);
    border-radius: 5px;
  }
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 105.5%;
    color: #2f2d2d;
    span {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 132.5%;
      color: #171b70;
    }
  }
  .v-card {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 132.5%;
    color: #171b70;
  }
}
.v-progress-linear {
  border-radius: 10px !important;
}
.white {
  background-color: white !important;
  border-color: white;
}
.days-left {
  height: 48px;
  width: 48px;
  border-radius: 40px;
  background: #c4c4c4;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 132.5%;
  /* or 21px */

  display: flex;
  align-items: center;
  justify-content: center;

  color: #000000;
}
.v-tabs-slider {
  background-color: #c1a13d
}
.actin_cursr {
  cursor: pointer;
}
.avatars {
  transform: scale(-1, 1);
  display: inline-block;
}
.avatar {
  position: relative;
  display: inline-block;
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}
.outl {
  background-color: #EEEEEE;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.myTable table thead, .v-data-table-header {
  background-color: #1E88E5 !important;
  color: #FFFFFF;
}
.avatar:not(:first-child) {
  margin-left: -10px;
}
.avatar img {
  display: block;
}
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0px !important
}
.loading {
  height: calc(100vh);
  position: absolute;
  display: block;
  width: 100%;
  top: 40%;
}
</style>
