<template>
   <div>
      <v-card class="pa-1 rounded-lg text-center" outlined @click="dialog = true">
         <div class="pa-3 pa-sm-8 grey lighten-5 ">
            <div><v-icon dark color="primary">mdi-plus</v-icon></div>
            <div class="text-subtitle-1 font-weight-bold primary--text">Add New Card</div>
         </div>
      </v-card>
    <v-dialog v-model="dialog" width="500">
      <v-card class="form-colored-bg pa-6">
        <div class="text-h6 primary--text mb-10">Order Card</div>
        <div class="font-weight-medium primary--text mb-2">Select Card Type</div>
        <v-select
         outlined 
         v-model="form.type" 
         :rules="[rules.required]"
         :items="['Virtual Card']"
         ></v-select>
        <v-form lazy-validation ref="form" v-model="valid">
        <div class="font-weight-medium primary--text mb-2">Card Model</div>
        <v-select
         outlined 
         v-model="form.model" 
         :rules="[rules.required]"
         :items="['VISA', 'MasterCard', 'Verve']"
         ></v-select>
        <div class="font-weight-medium primary--text mb-2">Select Currency</div>
        <v-select
         outlined 
         v-model="form.currency" 
         :rules="[rules.required]"
         :items="['USD', 'EUR']"
         ></v-select>
        <div class="font-weight-medium primary--text mb-2">How much would you fund the card?</div>
        <v-text-field 
         outlined 
         prefix="₦"
         v-model="form.amount"
         :rules="[rules.required, rules.min]"></v-text-field>
        <div class="font-weight-medium primary--text mb-2">Choose address option</div>
        <v-text-field outlined v-model="form.country" required :rules="[v=>!!v || 'This field is required']"></v-text-field>
        <v-btn class="primary" :loading="loading" block large @click="$refs.form.validate() ? orderCard() : null">Order Card</v-btn>
        <v-btn class="mt-3" depressed large @click="dialog = !dialog" block>Cancel</v-btn>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { states } from '@/util/statelga'
import {mapGetters, mapActions} from 'vuex'
import {industries} from '@/util/industries'
export default {
  data () {
    return {
      dialog: false,
      valid: true,
      loading: false,
      form: {},
      states: states,
      rules: {
        required: value => !!value || 'This field is required',
        min: value => value >= 10 || "Transfer amount can't go below ₦10",
      },
    }
  },
  methods: {
    ...mapActions('business', ['ordervirtualcard']),

    async orderCard() {
      this.loading = true
      try {
         let response =  await this.ordervirtualcard(this.form)
         this.$refs.form.reset()
         this.loading  = false
      } catch(error) {
         if(error.response && error.response.status === 422) {
            
         } else {
            this.$toast.error(error.response.data.message)
         }
         this.loading  = false
      }
    }
  },
  mounted() {
    this.form.user_id = this.user.id
  },
  computed : {
    ...mapGetters('auth', ['user'])
  }
}
</script>
<style lang="scss" scoped>

</style>
